.footer{
    background-color: #e57236;
    margin: 0px;
    width: 100%;
    min-height: 25vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
   
}
.end{
    background-color:#e57236;
}

.first, .second{
    width: 50%;
}
.first{
    display: flex;
    flex-direction: column;
    justify-content:left;
}

.icons{
    display: flex;
    justify-content: center;
    gap: 5px;
}

.home img{
    max-width: 100%;
}

@media screen and (max-width : 500px) {
    .footer{
        flex-direction: column;
        gap: 50px;
    }
}

a{
    text-decoration: none;
    text-align:end;
}
#contact .card:hover i,#contact .card:hover h4{
	color: #1a1707;
}

.img-fluid{
	height:300px;
	width: 100%;
}

a{
	text-decoration: none;
}
.td{
    color: #e57236;
}